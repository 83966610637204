import React, { useState } from 'react';
import './ProfileCard.css';

const ProfileCard = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const profiles = [
    {
      name: 'WINTECHNO',
      rating: [1, 1, 1, 1, 1],
      image: require("../photos/Group 12.png"),// Add image URL here
      description: "We are thrilled to share our exceptional experience with Wayug Team, who recently designed and developed a stunning website for our company, Wintechno Recycling India Pvt Ltd. From the initial consultation to the final launch, the team demonstrated utmost professionalism, creativity, and technical expertise.The website they crafted for us is not only visually appealing but also user-friendly, informative, and optimized for search engines.",

      altText: "Wintechno Recycling India Pvt Ltd"
    },
    {
      name: 'KHUD 11',
      rating: [1, 1, 1, 1, 0.5],
      image: require("../photos/Group 30 (1).png"), // Add image URL here
      description: "We are thrilled to share our exceptional experience with Wayug Team, who recently designed and developed a fantastic website and mobile app for our company, Khud 11 Live Score App. As a leading provider of live score updates and sports information, we needed a digital presence that would engage our audience, provide real-time updates, and offer a seamless user experience. Wayug Team hit a home run with our project! we're confident that they will play a significant role in our business growth.",

      altText: "Khud 11 Live Score App"
    },
    {
      name: 'RAINIER',
      rating: [1, 1, 1, 1, 0.5],
      image: require("../photos/Group 14.png"), // Add image URL here
   
        description: "We are thrilled to share our exceptional experience with Wayug Team, who recently designed and developed a fantastic website for our company, Rainier Project Ltd. As a leading provider of work contracts and job works, we needed a digital presence that would showcase our expertise, services, and values. Wayug Team exceeded our expectations in every way!Throughout the project, the Wayug Team was responsive, flexible, and open to our feedback and suggestions. They worked tirelessly to ensure that every aspect of the website met our expectations, and their attention to detail was impressive.",

      altText: "Rainier Project Ltd"
    },
    {
      name: 'FINTAX FIRST',
      rating: [1, 1, 1, 1,],
      image: require("../photos/ff.png"), // Add image URL here
   
        description: "We are absolutely delighted with the website that Wayug has designed and developed for Fintax First Choice Private Limited. From the initial consultation to the final launch, the team at Wayug was professional, responsive, and attentive to our needs.The website they created for us is not only visually stunning, but also highly functional and user-friendly. It perfectly captures the essence of our brand and effectively communicates the wide range of tax and accounting services we offer, including tax planning and preparation, financial statement preparation, bookkeeping, payroll processing, audit representation, and business advisory services.",

      altText: "FINTAX FIRST CHOICE PVT Ltd"
    },
    {
      name: 'STORYLINE',
      rating: [1, 1, 1, 1, 1],
      image: require("../photos/storyline.png"), // Add image URL here
   
        description: "Our collaboration with Wayug has been a game-changer for StoryLine, as they brought our vision to life with a website and app that exceed our wildest expectations, offering a captivating and user-friendly experience that has resonated with our audience worldwide.The StoryLine app is a masterpiece, offering a diverse collection of short stories across multiple languages and genres. Wayug's attention to detail and commitment to user experience have resulted in an app that is not only visually stunning but also incredibly intuitive and engaging. Our users can easily explore narratives that transcend cultural boundaries and evoke a range of emotions, from heartwarming tales to thrilling adventures.",

      altText: "Storyline.world "
    },
    {
      name: 'WAYUMART',
      rating: [1, 1, 1, 1,],
      image: require("../photos/wayumart.png"), // Add image URL here
   
        description: "We are absolutely delighted with the website that Wayug designed and developed for WayuMart, the e-commerce platform of Wayu Medicare India Pvt. Ltd. The team at Wayug truly understood our vision and goals, and their expertise in web development shone through in every aspect of the project.The website they created for us is modern, visually stunning, and incredibly user-friendly, providing our customers with a seamless online shopping experience. The intuitive navigation, clean design, and robust functionality have resulted in a significant increase in online sales and customer engagement.",

      altText: "wayumart pvt ltd"
    },
    // Add more profiles here
  ];

  const handlePrevClick = () => {
    setCurrentIndex((currentIndex - 1 + profiles.length) % profiles.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((currentIndex + 1) % profiles.length);
  };

  return (
    <div id="testimonial" className="profile-card-container">
      <h1>Testimonials</h1>
      <div className="profile-card active">
        <div className="left-container4">
          {profiles[currentIndex].image && (
            <img 
              src={profiles[currentIndex].image} 
              alt={profiles[currentIndex].altText} 
              title={profiles[currentIndex].title}
            />
          )}
        </div>
        <div className="right-container">
        <div className="content-0">

          <h2>{profiles[currentIndex].name}</h2>
 
          <div className="rating">
  {profiles[currentIndex].rating.map((rating, index) => (
    <i
      key={index}
      className={`fas fa-star${rating === 0.5 ? '-half-alt' : rating === 1 ? '' : ' fa-star-o'}`}
    />
  ))}
</div>
<h3>{profiles[currentIndex].title}</h3>
          <p>{profiles[currentIndex].description}</p>
          </div>
  
      <div className="profile-btn-container4">
        <button className="prev-btn1" onClick={handlePrevClick}>
          <i className="fas fa-chevron-left"></i>
        </button>
        <div className="profile-pagination">
          {profiles.map((profile, index) => (
            <div
              key={index}
              className={`profile-pagination-circle ${index === currentIndex ? 'active' : ''}`}
            ></div>
          ))}
        </div>
        <button className="next-btn1" onClick={handleNextClick}>
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default ProfileCard;
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const history = createBrowserHistory();

if (window.location.hostname === 'wayug.com') {
  history.push('https://www.wayug.com' + window.location.pathname);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [showNavLinks, setShowNavLinks] = useState(false);

  const handleMenuClick = () => {
    setShowNavLinks(!showNavLinks);
  };

  const handleLinkClick = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <nav className="navbar" role="navigation" aria-label="Main Navigation">
      <div className="logo">
        <img src={require('../photos/wayug logo.png')} alt="WAYUG Logo" />
        <h2>WAYUG</h2>
      </div>
      <ul className={`nav-links ${showNavLinks ? 'show' : 'hide'}`} aria-label="Navigation Menu">
        <li><a href="#" onClick={() => handleLinkClick('home')} aria-label="Home Page">Home</a></li>
        <li><a href="#" onClick={() => handleLinkClick('feature')} aria-label="Features Page">Features</a></li>
        <li><a href="#" onClick={() => handleLinkClick('testimonial')} aria-label="Testimonials Page">Testimonials</a></li>
        <li><a href="#" onClick={() => handleLinkClick('pricing')} aria-label="Pricing Page">Pricing</a></li>
        <li><a href="#" onClick={() => handleLinkClick('about-us')} aria-label="About Us Page">About Us</a></li>
        <li><a href="#" onClick={() => handleLinkClick('contact')} aria-label="Contact Us Page">Contact Us</a></li>
      </ul>
      <div className="menu-button" onClick={handleMenuClick} aria-label="Toggle Navigation Menu">
        {showNavLinks ? <i className="fas fa-times" aria-label="Close Navigation Menu" /> : <i className="fas fa-bars" aria-label="Open Navigation Menu" />}
      </div>
    </nav>
  );
};

export default Navbar;
import React from 'react';
import './pricing.css';

const Pricing = () => {
  const whatsappNumber = '8287999236'; // replace with your WhatsApp number
  const whatsappMessage = 'Hello, I am interested in your services.'; // replace with your desired message

  const handleContactUs = () => {
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${whatsappMessage}`;
    window.open(whatsappLink, '_blank');
  };

  return (
    <div id='pricing' className='pricing-section'>
      <h1>Pricing</h1>
      <section>
        <div className="plan-cards7">
          <div className="card8">
            <h3 class="popular2"></h3>
            <h2>Basic</h2>
            <p>Unlock your online potential with our affordable web solutions.</p>
            <h2>As per requirement<span></span></h2>
            <button onClick={handleContactUs}>Contact Us</button>
          </div>
          <div className="card7">
            <h3 class="popular">Popular</h3>
            <div class="card-content7">
              <h2>Professional</h2>
              <p>Quality websites at a price that works for you.</p>
              <h2>As per requirement<span></span></h2>

            </div>
            <button onClick={handleContactUs}>Contact Us</button>
          </div>
          <div className="card8">
            <h3 class="popular2"></h3>
            <h2>Company</h2>
            <p>Unlock your online potential with our affordable web solutions.</p>
            <h2>As per requirement<span></span></h2>
            <button onClick={handleContactUs}>Contact Us</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
import React from 'react';
import './about.css';

const AboutUs = () => {
  return (
    <div>
      <div id="about-us" className="about-us-container7">
        <h1>About Us</h1>
        <meta name="description" content="Learn more about Wayug, our vision, mission, and values." />

        <div className="right-container7">
          <img
            src={require('../photos/p1 home img.jpg')}
            alt="Wayug Team Photo"
            title="Wayug Team"
            width={800} // specify the width
            height={600} // specify the height
            loading="lazy" // add lazy loading for better performance
          />

          <div className="bottom-container7">
            <div className="left-box7">
              <div className="square1">
                <h3>Vision</h3>
              </div>
              <p>At Wayug, we embody the essence of innovation by seamlessly navigating the landscape where the world is of air.</p>
              <meta itemprop="description" content="Our vision is to innovate and navigate the ever-changing technological landscape." />
            </div>
            <div className="right-box7">
              <div className="square2">
                <h3>Mission</h3>
              </div>
              <p>Our commitment to excellence is reflected in our cutting-edge offering, empowering individuals and businesses to thrive in an ever-evolving technological era.</p>
              <meta itemprop="description" content="Our mission is to empower individuals and businesses to thrive in the ever-evolving technological era." />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
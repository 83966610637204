import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <section className="footer">
      <div className="container-o">
        <div className="left-o">
          <div className="logo">
            <img src={require('../photos/wayug logo.png')} alt="Wayug Logo" width="100%" height="auto" style={{ objectFit: 'contain' }} />
            <h2>WAYUG</h2>
          </div>
        </div>
        <div className="middle-o">
          <div className="columns-o">
            <div className="column-o">
              <h2><a href="#pricing" title="Pricing">Pricing</a></h2>
            </div>
            <div className="column-o">
              <h2><a href="#feature" title="Features">Features</a></h2>
            </div>
            <div className="column-o">
              <h2><a href="#about-us" title="About Us">About Us</a></h2>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <div className="footer-right">
          <div className="social-media">
            <a href="https://wa.me/8287999236" target="_blank" rel="no-opener no-referrer" title="WhatsApp">
              <i className="fab fa-whatsapp social-media-icon"></i>
            </a>
            <a href="tel:8287999236" title="Phone">
              <i className="fas fa-phone-alt"></i>
            </a>
            <a href="mailto:wayugpl@gmail.com" title="Email">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
          <div className="copyright">
            <p>Developed with <img src={require('../photos/heart2.png')} alt="Heart" width="15" height="15" /> <img src={require('../photos/copyright.png')} alt="Copyright" width="15" height="15" /><span> 2024 Wayug. All rights reserved.</span></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
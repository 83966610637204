import React from 'react';
import './home.css';

<meta name="viewport" content="width=device-width, initial-scale=1.0" />
function HomePage() {
  const handleRequestDemoClick = () => {
    const contactUsSection = document.querySelector('.contact');
    contactUsSection.scrollIntoView({ behavior: 'smooth' });
  }; 
  const whatsappNumber = '8287999236'; // replace with your WhatsApp number
  const whatsappMessage = 'Hello, I am interested in your services.'; // replace with your desired message

  const handleContactUsClick = () => {
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${whatsappMessage}`;
    window.open(whatsappLink, '_blank');
  };
  return (
    <section id="home" class="home">
    <div className="container1">
      <div className="header">
      <div class="heading"><h1>The tool you need to <span>build your software</span></h1></div>
        <div className="b1">
          <button onClick={handleRequestDemoClick}>Request Demo</button>
        </div>
      </div>
      <div className="hero">
        <img src={require('../photos/p1 home img.jpg')} alt="Hero Image" />
      </div>
      <div className="customers-container">
        <div class="heading"><h1>What We've Done ?</h1>
        <p> What We've Done Explore our latest projects and discover the impact we're making :)</p></div>
        <div className="customers-grid">
          <div className="customer-card">
          <img src={require('../photos/Group 12.png')} alt="WINTECHNO IMG"/>
          </div>
          <div className="customer-card">
          <img src={require('../photos/Group 30 (1).png')} alt="KHUD11 IMG"/>
          </div>
          <div className="customer-card">
          <img src={require('../photos/ff.png')} alt="KHUD11 IMG"/>
          </div>
          <div className="customer-card">
          <img src={require('../photos/wayumart.png')} alt="KHUD11 IMG"/>
          </div>
          <div className="customer-card">
          <img src={require('../photos/storyline.png')} alt="KHUD11 IMG"/>
          </div>
          <div className="customer-card">
          <img src={require('../photos/Group 14.png')} alt="RAINIER IMG"/>
          </div>
        </div>
      </div>
      
    </div>
    <div id="feature" className="post-boxes">
    <div class="heading"><h1>What We Can Do ?</h1>
    <p>Empowering your digital journey with Wayug's tailored solutions, where innovation knows no bounds :)</p></div>
    <div className="FEATURE-O">
    <div className="post-box8">
      <div className="post-left">
     <img src={require('../photos/feature3.jpg')} alt="Post Image" width="100%" height="150px" object-fit="cover" border-radius="10px" />
      </div>
      <div className="post-right">
        <h2>App Development Ios/Android</h2>
        <p>- Splash Screen</p>
        <p>- Firebase Auth/Cloud</p>
        <p>- Rest APIs</p>
        <p>- Maps Integration and More</p>
        <div className="post-meta0">
        <button className="btn" onClick={handleRequestDemoClick}>Try Now</button>
    
        </div>
      </div>
    </div>
    <div className="post-box9">
      <div className="post-left">
      <h2>Cutting Edge Web App Development</h2>
        <p>- Responsive And Scalable Design</p>
        <p>- Cross Browser Compatibility</p>
        <p>- Backend Integration</p>
        <p>- Progressive Web Apps (PWAs)</p>
        <p>- Customize Solution For Your Business Needs</p>
        <div className="post-meta0">
        <button className="btn" onClick={handleRequestDemoClick}>Try Now</button>
       
        </div>
      </div>
      <div className="post-right">
      <img src={require('../photos/feature1.jpg')} alt="Post Image" width="100%" height="150px" object-fit="cover" border-radius="10px" />
      </div>
    </div>
    <div className="post-box8">
      <div className="post-left">
        <img src={require('../photos/feature2.jpg')} alt="Post Image" width="100%" height="150px" object-fit="cover" border-radius="10px" />
      </div>
      <div className="post-right">
      <h2>Dynamic And User Friendly Websites</h2>
        <p>- Responsive LAyouts</p>
        <p>- Seo Optimization</p>
        <p>- Content Management System (CMS)</p>
        <p>- E-Commerce Integration</p>
        <p>- Secure And Robust Architecture</p>
        <div className="post-meta0">
        <button className="btn" onClick={handleRequestDemoClick}>Try Now</button>
        
        </div>
      </div>
    </div>
    </div>
    </div>
    <div className="full-width-template">
        <div className="left-part">
        <div class="heading">Get Started!</div>
          <h2>
            Tech Solution as Light as Air: Wayug's Commitment to Excellence.
          </h2>
          <button className="contact-us-button" onClick={handleContactUsClick}>
            Contact Us
          </button>
        </div>
        <div className="right-part" style={{ width: "50%" }}>
          <img
            src={require("../photos/wayug-1.png")}
            alt="wayug-logo"
            className="image"
          />
        </div>
      </div>
    </section>

  );
}

export default HomePage;
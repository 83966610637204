import { default as React } from 'react';
import './App.css';
import AboutUs from './components/about';
import Contact from './components/contact';
import Footer from './components/footer';
import HomePage from './components/home';
import Navbar from './components/Navbar';
import Pricing from './components/pricing';
import ProfileCard from './components/ProfileCard';

function App() {
  return (
    <div>
      <Navbar />
      <HomePage />
      <ProfileCard />
      <Pricing/>
      <AboutUs />
 
      <Contact/>
      <Footer/>

    </div>
  );
}

export default App;
import React from 'react';
import './contact.css';

function Contact() {
  return (
    <div id="contact" className="contact" lang="en">
      <div className="container-n">
        <h1>Get In Touch</h1>
        <p>Let's build something together :)</p>
        <div className="contact-boxes-container">
          <div className="contact-box">
            <i className="fa fa-home" aria-hidden="true"></i>
            <h3>Location</h3>
            <p>IP Extension, Delhi</p>
            <meta itemprop="address" content="IP Extension" />
          </div>
          <div className="contact-box">
            <i className="fa fa-phone" aria-hidden="true"></i>
            <h3>Phone</h3>
            <p><a href="tel:8287999236" title="Call us" rel="no-follow">8287999236</a></p>
            <meta itemprop="telephone" content="+18287999236" />
          </div>
          <div className="contact-box">
            <i className="fa fa-envelope" aria-hidden="true"></i>
            <h3>Email</h3>
            <p><a href="mailto:wayugpl@gmail.com" title="Email us" rel="no-follow">wayugpl@gmail.com</a></p>
            <meta itemprop="email" content="wayugpl@gmail.com" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;